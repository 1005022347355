var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.agencyTel
    ? _c(
        "v-btn",
        {
          class: _vm.buttonClass,
          staticStyle: { "font-size": "10px" },
          attrs: {
            id: "agencyTel",
            width: _vm.agencyTelWidth,
            height: "28px",
            color: "next",
            rounded: "",
            outlined: "",
            href: "tel:" + _vm.agencyTel.replace(/-/g, ""),
          },
        },
        [
          _c(
            "v-icon",
            { staticClass: "mr-0", attrs: { size: "12px", left: "" } },
            [_vm._v("phone")]
          ),
          _vm._v("\n  " + _vm._s(_vm.$t("button.agencyTel")) + "\n  "),
          _vm.checkPc
            ? [_vm._v("\n    （" + _vm._s(_vm.agencyTel) + "）\n  ")]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }