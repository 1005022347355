import { set } from '@/utils/vuex';
import {
  FIX,
  NEED_UPLOAD,
  SELECTED_PLAN,
  PLANS,
  AGENCY_NAME,
  AGENCY_PHONE_NUMBER,
  APPLICATIONFORMS,
  DOWNLOAD_DOC,
  PAGE_SUM,
  PENDING_STEP,
  PENDING_APPLICATIONFORMS_PAGE,
  IS_AGREE_CHECKED,
  IS_PROCEDURE_SKIPPED,
  URL_TOKEN,
} from './types';

export default {
  FIX: set(FIX),
  NEED_UPLOAD: set(NEED_UPLOAD),
  SELECTED_PLAN: set(SELECTED_PLAN),
  PLANS: set(PLANS),
  AGENCY_NAME: set(AGENCY_NAME),
  AGENCY_PHONE_NUMBER: set(AGENCY_PHONE_NUMBER),
  DOWNLOAD_DOC: set(DOWNLOAD_DOC),
  PAGE_SUM: set(PAGE_SUM),
  PENDING_STEP: set(PENDING_STEP),
  PENDING_APPLICATIONFORMS_PAGE: set(PENDING_APPLICATIONFORMS_PAGE),
  IS_AGREE_CHECKED: set(IS_AGREE_CHECKED),
  IS_PROCEDURE_SKIPPED: set(IS_PROCEDURE_SKIPPED),
  URL_TOKEN: set(URL_TOKEN),
};
