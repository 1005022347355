export const NEED_UPLOAD = 'NEED_UPLOAD';
export const SELECTED_PLAN = 'SELECTED_PLAN';
export const PLANS = 'PLANS';
export const AGENCY_NAME = 'AGENCY_NAME';
export const AGENCY_PHONE_NUMBER = 'AGENCY_PHONE_NUMBER';
export const DOWNLOAD_DOC = 'DOWNLOAD_DOC';
export const PAGE_SUM = 'PAGE_SUM';
export const PENDING_STEP = 'PENDING_STEP';
export const PENDING_APPLICATIONFORMS_PAGE = 'PENDING_APPLICATIONFORMS_PAGE';
export const IS_AGREE_CHECKED = 'IS_AGREE_CHECKED';
export const IS_PROCEDURE_SKIPPED = 'IS_PROCEDURE_SKIPPED';
export const URL_TOKEN = 'URL_TOKEN';
