var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-content",
    { staticClass: "pa-0" },
    [
      _vm.auth === true
        ? [
            !_vm.headerLogoHidden || !_vm.stepperHidden
              ? [
                  _c("Header", {
                    attrs: {
                      stepPoint: _vm.stepPoint,
                      agencyName: _vm.agencyNameValue,
                      agencyTel: _vm.agencyPhoneNumberValue,
                      stepper: _vm.stepper,
                      applicationFormPage: _vm.applicationFormPage,
                      applicationFormTotalPage: _vm.applicationFormTotalPage,
                      stepperHidden: _vm.stepperHidden,
                      headerTitleHidden: _vm.headerTitleHidden,
                      telIconHidden: _vm.telIconHidden,
                      stepperText: _vm.stepperText,
                    },
                  }),
                ]
              : _vm._e(),
            _c("router-view", {
              style: _vm.computedStyle,
              attrs: { stepPoint: _vm.stepPoint },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }