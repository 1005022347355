export default {
  themes: {
    light: {
      primary: {
        0: '#000000',
        1: '#000000',
      },
      secondary: '#000000',
      tertiary: '#000000',
      accent: '#000000',
      error: '#cc0022',
      info: '#000000',
      success: '#000000',
      warning: '#000000',
      next: '#cc0022',
      back: '#ff7a7a',
      header: '#ffffff',
      default: '#000000',
      steppersIcon: '#66cdaa',
      calendar: '#0000cd',
      download: '#659d16',
      upload: '#c1c1c1',
      disabled: '#c1c1c1',
      selectedPlan: '#a0cea8',
      loading: '#1e90ff',
      edit: '#008000',
    },
  },
};
