import moment from 'moment';
import {
  ContractStatus,
  ContractStatusEditMap,
  ContractStatusDisplay,
  ContractReadStatus,
  ContractExpiration,
} from '@/lib/const';

export class Status {
  // 締結済であるかどうか
  static hasConcluded(status) {
    return ![ContractStatus.Processing, ContractStatus.Waiting].includes(
      status
    );
  }

  // 閲覧可であるかどうか
  static isAvailable(readStatus) {
    return readStatus === ContractReadStatus.Available;
  }

  // 続行可であるかどうか
  static isContinuable(status) {
    return [ContractStatus.Processing, ContractStatus.Waiting].includes(status);
  }

  // 案件閲覧ステータスの取得
  static getContractRead(status, startDate) {
    // 案件が締結済であるかどうか
    const hasConcluded = this.hasConcluded(status);

    // 締結済であるかつ保険始期日から規定の月数が経過している場合、閲覧不可とする
    const hasNotAccess =
      hasConcluded &&
      moment().isSameOrAfter(
        moment(startDate)
          .startOf('day')
          .add(ContractExpiration[ContractReadStatus.HasNotAccess], 'month')
      );

    // 締結済でないかつ保険始期日から規定の日数が経過している場合、手続き不可とする
    const hasClosed =
      !hasConcluded &&
      moment().isSameOrAfter(
        moment(startDate)
          .startOf('day')
          .add(ContractExpiration[ContractReadStatus.HasClosed], 'day')
      );

    return hasClosed
      ? ContractReadStatus.HasClosed
      : hasNotAccess
      ? ContractReadStatus.HasNotAccess
      : ContractReadStatus.Available;
  }

  // ステータスの選択肢の取得
  static getChoices(status) {
    return (
      Object.keys(ContractStatusDisplay)
        // 変更可能な権限のみ
        .filter(key => this.isUpdatable(status, Number(key)))
        // 選択肢用にマッピング
        .map(key => ({
          text: ContractStatusDisplay[key],
          value: Number(key),
        }))
    );
  }

  // 変更可能であるかどうか
  static isUpdatable(currentStatus, newStatus) {
    return !!ContractStatusEditMap.Accept[currentStatus][newStatus];
  }

  // ステータス変更イベントの取得
  static getStatusChangeEvent(currentStatus, newStatus) {
    return ContractStatusEditMap.Accept[currentStatus][newStatus];
  }
}
