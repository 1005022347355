export default [
  {
    path: '/',
    name: 'Home',
    view: 'Home',
    meta: { requiredAuth: false },
  },
  {
    path: '/tos',
    name: 'Tos',
    view: 'Tos',
    meta: { requiredAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    view: 'Login',
    meta: { requiredAuth: false },
  },
  {
    path: '/auth',
    name: 'Auth',
    view: 'Auth',
    meta: { requiredAuth: false },
  },
  {
    path: '/lockout',
    name: 'Lockout',
    view: 'Lockout',
    meta: { requiredAuth: false },
  },
  {
    path: '/base_info',
    name: 'BaseInfo',
    view: 'BaseInfo',
    meta: { requiredAuth: true },
  },
  {
    path: '/explan_important_matters',
    name: 'ExplanImportantMatters',
    view: 'ExplanImportantMatters',
    props: true,
    meta: { requiredAuth: true },
  },
  {
    path: '/check_need_upload#final_review_contract',
    name: 'CheckNeedUploadFinalReviewContract',
    view: 'CheckNeedUpload',
    props: true,
    meta: { requiredAuth: true },
  },
  {
    path: '/review_contract/:pageNumber',
    name: 'ReviewContract',
    view: 'ReviewContract',
    meta: { requiredAuth: true },
  },
  {
    path: '/error',
    name: 'Error',
    view: 'Error',
    meta: { requiredAuth: true },
  },
  {
    path: '/read_error',
    name: 'ReadError',
    view: 'ReadError',
    meta: { requiredAuth: true },
  },
  {
    path: '/check_need_upload',
    name: 'CheckNeedUpload',
    view: 'CheckNeedUpload',
    meta: { requiredAuth: true },
  },
  {
    path: '/procedure_complete',
    name: 'ProcedureComplete',
    view: 'ProcedureComplete',
    meta: { requiredAuth: true },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    view: 'Maintenance',
    meta: { requiredAuth: false },
  },
];
